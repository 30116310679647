<template>

  <div class="contest-users">
    <h1>
      Contest Users <small v-if="total">({{ total }})</small>
    </h1>

    <div class="filter-module">
      <p class="filter-module-title">Filter Users by COMPLETED session</p>

      <div class="filter-dropdown">
        <label>Select a Milestone:</label>
        <select v-model="selectedSectionId">
          <option value="" disabled>Select a section</option>
          <option v-for="section in sections" :key="section.id" :value="section.id">
            {{ section.title.en.title }}
          </option>
        </select>
        <span v-if="filterLoading" class="loading-text">Loading...</span>
        <div v-if="filterLoading" class="spinner"></div>
      </div>

      <div class="filter-dropdown" v-if="sessions && sessions.length > 0">
        <label>Select an Activity:</label>
        <select v-model="selectedSessionId">
          <option value="" disabled>Select a session</option>
          <option v-for="session in sessions" :key="session.id" :value="session.id">
            {{ session.title.en.title }}
          </option>
        </select>
        <button class="filter-btn" @click="resetFilters">Reset</button>
        <span v-if="filterLoading" class="loading-text">Loading...</span>
        <div v-if="filterLoading" class="spinner"></div>
      </div>

    </div>


<!--    <div class="button-container">-->
<!--      <span v-if="isLoading" class="loading-text">Loading...</span>-->
<!--      <div v-if="isLoading" class="spinner"></div>-->
<!--      <button class="export-btn" @click="copyUnverifiedEmailsToClipboard()">Copy Unverified Emails</button>-->
<!--    </div>-->


    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['edit']"
        @update-table="fetchUsers"
        @edit="editHandler"
    />

    <InspectContestUser
        v-if="editItem"
        :item="editItem"
        @close="editItem = null"
    />

  </div>
</template>

<script>
import usersMixin from "@/mixins/users.vue";
import knowledge from "@/mixins/knowledge.vue";
import organizationsMixin from "@/mixins/organizations.vue";
import DataTableHolder from "@/components/shared/datatable/DataTableHolder.vue";
import InspectContestUser from "@/components/marketing/popups/InspectContestUser.vue";

export default {
  name: 'ContestUsers',
  mixins: [usersMixin, organizationsMixin, knowledge],
  components: {
    DataTableHolder, InspectContestUser
  },
  data() {
    return {

      rows: null,
      total: null,
      pages: null,
      updateResults: false,
      editItem: null,
      emailString: null,
      isLoading: false,
      filterLoading: false,
      selectedSectionId: null,
      sections: [],
      selectedSessionId: null,
      sessions: [],

      columns: [
        {
          title: "#",
          name: "id",
          sortable: false,

        },
        {
          title: "User ID",
          name: "userId",
          sortable: true,
        },
        {
          title: "First Name",
          name: "fistName",
          sortable: false,
        },
        {
          title: "Last Name",
          name: "lastName",
          sortable: false,
        },
        {
          title: "Email",
          name: "email",
          sortable: false,
        },
        {
          title: "is Verified",
          name: "isVerified",
          sortable: true,
        },
        {
          title: "Total Points",
          name: "totalPoints",
          sortable: true,
        },
        {
          title: "Portfolio ID",
          name: "portfolioId",
          sortable: true,
        },
        {
          title: "Portfolio Name",
          name: "portfolioName",
          sortable: false,
        },
        {
          title: "Overall Performance",
          name: "overallPerformance",
          sortable: true,
        },
        {
          title: "Cash",
          name: "cash",
          sortable: true,
        },
        {
          title: "Registered On",
          name: "registeredOn",
          sortable: true,
        },

      ]
    }
  },

  methods: {
    fetchUsers(data) {
      this.filterLoading = true;

      // Assign filters
      if (this.selectedSectionId) {
        data.filter.push({
          name: 'sectionFilter',
          term: this.selectedSectionId,
        });
      }

      if (this.selectedSessionId) {
        data.filter.push({
          name: 'sessionFilter',
          term: this.selectedSessionId,
        });
      }

      this.getContestOverview(data)
          .then((res) => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let usersFormatted = [];
            // * Loop through users and add only the needed properties
            for (const user of res.userRows) {

              let userObj = {
                id: user.unique_row_identifier,
                userId: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                isVerified: user.email_verified,
                totalPoints: user.total_points,
                portfolioId: user.portfolio_id,
                portfolioName: user.portfolio_name,
                overallPerformance: user.overall_performance,
                cash: user.cash,
                registeredOn: this.formatDate(user.registered_on),
              };
              // * Add formatted user to formatted users array
              usersFormatted.push(userObj);
            }
            // * Assign table rows to be the formatted users array
            this.rows = usersFormatted;
            this.total = res.resultsCount;
            this.filterLoading = false;
          })
          .catch((err) => {
            if (err === 401) {
              this.logout();
            }
          });
    },

    editHandler(item) {
      this.editItem = item;
    },

    updatedHandler() {
      this.editItem = null;
      this.updateResults = !this.updateResults;
    },

    fetchSectionsByModule(moduleId){
      this.filterLoading = true;
      this.getSectionsByModule(moduleId)
          .then(response => {
            this.sections = response;
            this.filterLoading = false;
          })
          .catch(err => {
            console.error('Failed to get Sections: ', err);
            this.logout();
            this.filterLoading = false;
          });
    },

    resetFilters() {
      this.selectedSectionId = null;
      this.selectedSessionId = null;
      this.sessions = [];
      this.fetchUsers({ filter: [] });
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toISOString().split('T')[0];
    }

    // copyUnverifiedEmailsToClipboard(){
    //   this.isLoading = true;
    //   this.getContestUserEmails()
    //       .then(response => {
    //         this.emailString = response.emailString;
    //
    //         // Copying to clipboard
    //         if (navigator.clipboard) {
    //           navigator.clipboard.writeText(this.emailString).then(() => {
    //
    //           }).catch(err => {
    //             console.error('Failed to copy emails: ', err);
    //           });
    //           this.isLoading = false;
    //         }
    //
    //       })
    //       .catch(err => {
    //         if (err === 401) {
    //           this.logout();
    //         }
    //       })
    // },


  },

  watch: {
    selectedSectionId(newVal) {
      const selectedSection = this.sections.find(section => section.id === newVal);
      this.sessions = selectedSection ? selectedSection.sessions : [];
      this.selectedSessionId = null;
    },

    selectedSessionId(newVal, oldVal) {
      // Only proceed if a session has actually been selected
      if (newVal !== null && newVal !== oldVal) {
        this.selectedSessionId = newVal;
        this.fetchUsers({ filter:[] })
      }
    },

  },

  created() {
    this.fetchSectionsByModule(20);
  }

}
</script>

<style scoped>

.filter-module {
  background-color: #f7f9fc; /* Light background color for distinction */
  padding: 15px 20px; /* Padding to give space inside the module */
  border-radius: 10px; /* Rounded corners for a smoother appearance */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  margin-bottom: 25px; /* Space between the filter module and the table */
}

.filter-module-title {
  font-size: 20px;
  color: #3c6e71;
  margin-bottom: 10px; /* A bit of spacing between the title and the filter */
  font-weight: 600; /* Making the text slightly bold for emphasis */
}

.filter-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.filter-dropdown label {
  font-size: 18px;
  margin-right: 15px;
  color: #3c6e71;
  font-weight: 500;
}

.filter-dropdown select {
  width: 190px;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  padding: 9px 15px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
  margin-right: 10px;
}

.filter-dropdown input[type="text"] {
  width: 340px;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  padding: 9px 15px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
  margin-right: 10px;
}

.filter-dropdown select:focus, .filter-dropdown input[type="text"]:focus {
  border-color: #3c6e71;
}

.filter-dropdown select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%233c6e71" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>') no-repeat right 1rem center/8px 10px, #ffffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.filter-dropdown .filter-btn {
  background: #3c6e71;
  color: #FFF;
  border: none;
  font-weight: 600;
  height: 45px;
  width: 100px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.filter-dropdown .filter-btn:hover {
  background: #295456;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.filter-dropdown .filter-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.loading-text {
  margin-left: 10px;
  font-size: 16px;
  color: #3c6e71;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3c6e71;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 25px;
}
</style>
