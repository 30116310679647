<template>
  <div class="user-page login-page">
    <div class="login-register-box">
      <!-- Left column of the login screen -->
      <div class="left-col">
        <div class="logo-and-btn-holder">
          <div class="logo">
            <router-link to="/">
              <img class="icon" :src="$s3Url + 'svgs/logo.svg'">
              <span class="text">UMU Analytics</span>
            </router-link>
          </div>
        </div>
        <div class="image-holder">
          <img :src="$s3Url + 'user/login-register-image.png'">
        </div>
        <h3>Select Investments that will make you grow</h3>
      </div>
      <!-- Right column of the login screen -->
      <div class="right-col relative">
        <!-- Logo -->
        <div class="logo">
          <router-link to="/">
            <img class="icon" :src="$s3Url + 'svgs/logo.svg'">
            <span class="text">UMU Analytics</span>
          </router-link>
        </div>
        <h2>Log In to your account</h2>
        <!-- Login form with fields -->
        <form>
          <div class="field-holder relative">
            <img class="field-icon" :src="$s3Url + 'svgs/icons/sign-mail.svg'">
            <input type="email" v-model="email" name="email" id="email" placeholder="Email" @input="v$.email.$touch()" :class="{error: v$.email.$error}">
            <template v-if="v$.email.$error">
              <div v-if="!v$.email.required" class="input-error">Please enter an email</div>
              <div v-if="!v$.email.email" class="input-error">Email address is invalid</div>
            </template>
          </div>
          <div class="field-holder relative">
            <img class="field-icon" :src="$s3Url + 'svgs/icons/sign-lock.svg'">
            <input :type="passwordFieldType" v-model="password" placeholder="Password" @input="v$.password.$touch()" :class="{error: v$.password.$error}">
            <div class="show-hide-password">
              <img :src="$s3Url + 'svgs/icons/show-pass.svg'" v-if="passwordFieldType == 'password'" @click="passwordFieldType = 'text'">
              <img :src="$s3Url + 'svgs/icons/hide-pass.svg'" v-else @click="passwordFieldType = 'password'">
            </div>
            <template v-if="v$.password.$error">
              <div v-if="!v$.password.required" class="input-error">Please enter a password</div>
            </template>
          </div>
          <span class="success-message" v-if="info">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
            {{ info }}
          </span>
          <span class="error-message" v-if="error">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
            {{ error }}
          </span>
          <button
            class="transition"
            :disabled="v$.$invalid || loginLoading"
            type="button"
            @click="submitForm()"
          >
            <span v-if="!loginLoading">Login</span>
            <font-awesome-icon v-else icon="spinner" spin />
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import userMixin from '@/mixins/user'

  import { useVuelidate } from '@vuelidate/core'
  import { required, email } from '@vuelidate/validators'

  import './user-pages.css'

  export default {
    name: 'Login',
    mixins: [userMixin],
    metaInfo: {
      title: 'Login'
    },
    data() {
      return {
        email: '',
        password: '',
        passwordFieldType: 'password',
        remember: true,
        error: '',
        info: '',
        loginLoading: false,
        v$: useVuelidate()
      }
    },
    methods: {
      // * Submit login form function
      submitForm() {
        // * Set login button loading
        this.loginLoading = true;

        // * Construct request data
        const dataToSend = {
          username: this.email.toLowerCase(),
          password: this.password
        }

        let prevRoute = this.$route.query.prev;

        this.login(dataToSend, this.remember)
          .then(res => {
            this.error = '';
            if (res && res['2fa_required'] == true) {
              this.info = res['msg'];
            }

            if (prevRoute) {
              this.$router.push(prevRoute);
            } else {
              this.$router.push('/')
            }
            this.loginLoading = false;
          })
          .catch(error => {
            this.info = '';
            if (error && error['msg']) {
              this.error = error['msg'];
            } else {
              this.error = 'No access, please go to umushroom.com.';
            }

            this.loginLoading = false;
          })
      }
    },
    validations: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    },
    mounted() {
      // * Set user component in the auth store (in order for the header and footer to disappear)
      this.$store.commit('setUserComponent', true);
    },
    unmounted() {
      this.$store.commit('setUserComponent', false);
    }
  }
</script>

<style scoped>
  .input-error {
    color: #ef2d2d;
    font-size: 14px;
    position: absolute;
    bottom: 0px;
    transform: translateY(100%);
  }

  input.error {
    border-color: #ef2d2d;
  }

  input.error::placeholder {
    color: #ef2d2d;
  }
</style>
