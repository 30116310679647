<template>
    <popup title="Add Setting" @close="$emit('close')">
        <template v-slot:content>
            <!-- Context Selection -->
            <div class="field">
                <label for="context">Context:</label>
                <select id="context" v-model="selectedContext" @change="onContextChange">
                    <option value="home">Home</option>
                    <option value="masterclass">Masterclass</option>
                    <option value="banners">Banners</option>
                </select>
            </div>

            <!-- Type Selection -->
            <div class="field" v-if="selectedContext">
                <label for="type">Type:</label>
                <select id="type" v-model="selectedType">
                    <option v-for="type in availableTypes" :key="type" :value="type">
                        {{ type }}
                    </option>
                </select>
            </div>

            <!-- Form Fields Based on Selected Type -->
            <div v-if="selectedType">
                
                <!-- Home context fields -->
                <div v-if="selectedType === 'home_slider'">
                    <!-- Article Dropdown -->
                    <div class="field">
                        <label for="article">Article:</label>
                        <select id="article" v-model="selectedArticleId">
                            <option v-for="article in articles" :key="article.id" :value="article.id">
                                {{ article.title }} ({{ article.organization }})
                            </option>
                        </select>
                    </div>
                    
                    <!-- Expires On Datepicker -->
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>

                <!-- Featured Asset Manager -->
                <div v-else-if="selectedType === 'featured_asset_manager'">
                    <div class="field">
                        <label for="partner">Partner:</label>
                        <select id="partner" v-model="selectedPartnerId">
                            <option v-for="partner in partners" :key="partner.id" :value="partner.id">
                                {{ partner.name }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>

                <!-- Masterclass Fields -->
                <div v-else-if="['masterclass_beginner', 'masterclass_advanced'].includes(selectedType)">
                    <div class="field">
                        <label for="masterclassTitle">Title:</label>
                        <input type="text" id="masterclassTitle" v-model="masterclassTitle" />
                    </div>
                    <div class="field">
                        <label for="masterclassDate">Date:</label>
                        <input type="date" id="masterclassDate" v-model="masterclassDate" />
                    </div>
                    <div class="field">
                        <label for="tagId">Tag:</label>
                        <select id="tagId" v-model="selectedTagId">
                            <option v-for="tag in tags" :key="tag.id" :value="tag.id">
                                {{ tag.name }}
                            </option>
                        </select>
                    </div>
                  
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>

                <!-- Organization Banner -->
                <div v-else-if="selectedType === 'organization_banner'">
                    <div class="field">
                        <label>EN - Text:</label>
                        <input type="text" v-model="enBannerText" placeholder="Banner text message" />
                        <label>EN - Link:</label>
                        <input type="text" v-model="enBannerLink" placeholder="Link URL" />
                        <label>EN - Link Text:</label>
                        <input type="text" v-model="enBannerLinkText" placeholder="Link display text" />
                    </div>
                    <div class="field">
                        <label>DE - Text:</label>
                        <input type="text" v-model="deBannerText" placeholder="Banner text message DE" />
                        <label>DE - Link:</label>
                        <input type="text" v-model="deBannerLink" placeholder="Link URL DE" />
                        <label>DE - Link Text:</label>
                        <input type="text" v-model="deBannerLinkText" placeholder="Link display text DE" />
                    </div>
                    
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>

                <!-- Most Visited Equity Fields -->
                <div v-else-if="selectedType === 'most_visited_equity'">
                    <div class="field">
                        <label for="search">Search Equity (Name or ISIN):</label>
                        <input type="text" id="search" v-model="searchTerm" @keyup.enter="searchEquity" placeholder="Enter at least 3 characters" />
                        <div v-if="searchTerm.length > 0 && searchTerm.length < 3" class="warning-message">Please enter at least 3 characters.</div>
                    </div>
                    <div v-if="securities.length > 0" class="field">
                        <label for="security">Select Security:</label>
                        <select id="security" v-model="selectedSecurityId" @change="updateSelectedSecurity">
                            <option v-for="security in securities" :key="security.id" :value="security.id">
                                {{ security.name }} ({{ security.isin }})
                            </option>
                        </select>
                    </div>
                    <div v-if="loadingSecurities">Loading securities...</div>
                    
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>
            </div>

            <!-- Create Button -->
            <button class="transition save-btn" @click="createSettingHandler()" :disabled="createLoading">
                <template v-if="!createLoading">Create</template>
                <font-awesome-icon v-else icon="spinner" spin />
            </button>
        </template>
    </popup>
</template>

<script>
import Popup from "@/components/shared/Popup.vue";
import axios from "axios";

export default {
    name: "NewSetting",
    components: { Popup },
    data() {
        return {
            selectedContext: null,
            selectedType: null,
            availableTypes: [],
            articles: [],
            partners: [],
            securities: [],
            tags: [],
            selectedArticleId: null,
            selectedPartnerId: null,
            selectedSecurityId: null,
            selectedTagId: null,
            searchTerm: '',
            loadingSecurities: false,
            expiresOn: null,
            createLoading: false,
            masterclassTitle: "",
            masterclassDate: null,
            enBannerText: "",
            enBannerLink: "",
            enBannerLinkText: "",
            deBannerText: "",
            deBannerLink: "",
            deBannerLinkText: ""
        };
    },
    methods: {
        onContextChange() {
            this.selectedType = null; // Reset type when context changes
            if (this.selectedContext === "home") {
                this.availableTypes = [
                    "featured_articles",
                    "featured_asset_manager",
                    "home_slider",
                    "latest_movement",
                    "most_visited_equity",
                    "star_of_the_week",
                    "upcoming_events"
                ];
                this.fetchArticles();
            } else if (this.selectedContext === "masterclass") {
                this.availableTypes = ["masterclass_advanced", "masterclass_beginner"];
                this.fetchTags();
            } else if (this.selectedContext === "banners") {
                this.availableTypes = ["organization_banner"];
            }
        },
        fetchArticles() {
            axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/articles`).then(res => {
                this.articles = res.data.map(article => ({
                    id: article.article_id,
                    title: article.article_title,
                    organization: article.organization_name
                }));
            });
        },
        fetchPartners() {
            axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/partners`).then(res => {
                this.partners = res.data;
            });
        },
        fetchTags() {
            axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/tags`).then(res => {
                this.tags = res.data.tags.map(tag => ({
                    id: tag.tagId,
                    name: tag.name
                }));
            });
        },
        searchSecurities() {
            this.loadingSecurities = true;
            axios.post(`${this.$store.state.global.apiUrl}/backoffice/settings/search-securities`, {
                searchTerm: this.searchTerm 
            })
            .then(res => {
                this.securities = res.data;
                this.loadingSecurities = false;
            })
            .catch(err => {
                console.error("Error searching securities:", err);
                this.loadingSecurities = false;
            });
        },
        createSettingHandler() {
            this.createLoading = true;
            const dataToSend = {
                context: this.selectedContext,
                type: this.selectedType,
                isActive: true,
                expiresOn: this.expiresOn,
                articleId: this.selectedArticleId,
                partnerId: this.selectedPartnerId,
                securityId: this.selectedSecurityId,
                ...(this.selectedType === "organization_banner" && {
                    bannerOptions: {
                        en: { text: this.enBannerText, link: this.enBannerLink, linkText: this.enBannerLinkText },
                        de: { text: this.deBannerText, link: this.deBannerLink, linkText: this.deBannerLinkText }
                    }
                }),
                ...(this.selectedType.includes("masterclass") && {
                    masterclassOptions: {
                        title: this.masterclassTitle,
                        date: this.masterclassDate,
                        tagId: this.selectedTagId
                    }
                })
            };

            axios
                .post(`${this.$store.state.global.apiUrl}/backoffice/settings/new`, dataToSend)
                .then(() => {
                    this.createLoading = false;
                    this.$emit("created");
                })
                .catch(err => {
                    this.createLoading = false;
                    console.error("Error creating setting:", err);
                });
        }
    },
    watch: {
        selectedType(newType) {
            if (newType === "featured_asset_manager") {
                this.fetchPartners();
            }
            if (newType === "most_visited_equity") {
                this.securities = []; // Reset securities when type changes
                this.searchTerm = ''; // Clear search term to avoid accidental triggers
            }
        },
        searchTerm(newTerm) {
            if (newTerm.length >= 3) {
                this.searchSecurities();
            }
        }
    },
};
</script>

<style scoped>
.field {
    margin-bottom: 20px;
}

.field label {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
}

.field input,
.field select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.save-btn {
    background: #3c6e71;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-btn:hover {
    background: #295456;
}

.save-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
</style>