<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      // ---------------------------------------------
      // Commands
      // ---------------------------------------------
      // * Get commands log from API
      getCommandsLog() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/command/logs`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Get commands log paginated from API
      getCommandsPaginatedLog(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/command/logs`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Get commands chart view
      getCommandsChartView(days) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/command/logs-chart-view${days ? `?days=${days}` : ''}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Get commands from the API
      getCommandsPaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/commands`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      getCommands() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/commands`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Create a new command
      createCommand(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/command`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Update existing command
      editCommand(commandId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/command/${commandId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Get all micro services
      getMicroServices() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/commands/microservices`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Get commands schedule from the API
      getCommandsSchedulePaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/command/schedules`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      createCommandSchedule(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/command/schedule`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      editCommandSchedule(commandId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/command/schedule/${commandId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // ---------------------------------------------
      // FAQs
      // ---------------------------------------------
      // * Get faqs from API
      getFaqs() {
        return new Promise((resolve, reject) => {
          let instance = axios.create();
          instance.defaults.headers.common = {};
          let timestamp = Date.now();

          instance.get(`${this.$s3Data}faq/faq.json?v=${timestamp}`)
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Add new faq
      addNewFaq(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/faq`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      updateFaq(faqId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/faq/${faqId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Delete FAQ
      deleteFaq(faqId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/faq/${faqId}`, {
            method: 'DELETE'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Get FAQ categories
      getFaqCategories() {
        return new Promise((resolve, reject) => {
          let instance = axios.create();
          instance.defaults.headers.common = {};
          let timestamp = Date.now();

          instance.get(`${this.$s3Data}faq/categories.json?v=${timestamp}`)
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            })
        });
      },
      // * Add new FAQ category
      addNewFaqCategory(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/faq/category`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Edit FAQ category
      editFaqCategory(categoryId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/faq/category/${categoryId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // ---------------------------------------------
      // Glossary
      // ---------------------------------------------
      getGlossaryCategories() {
        return new Promise((resolve, reject) => {
          let instance = axios.create();
          instance.defaults.headers.common = {};
          let timestamp = Date.now();

          instance.get(`${this.$s3Data}glossary/categories.json?v=${timestamp}`)
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      addGlossaryCategory(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/glossary/category`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      editGlossaryCategory(categoryId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/glossary/category/${categoryId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      getGlossaries() {
        return new Promise((resolve, reject) => {
          let instance = axios.create();
          instance.defaults.headers.common = {};
          let timestamp = Date.now();

          instance.get(`${this.$s3Data}glossary/entries.json?v=${timestamp}`)
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      addNewGlossary(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/glossary`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      updateGlossary(glossaryId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/glossary/${glossaryId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Delete Glossary
      deleteGlossary(glossaryId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/glossary/${glossaryId}`, {
            method: 'DELETE'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },

      /*
      * Umu Benchmarks
      * */

      getUmuBenchmarksPaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/umu-benchmarks`, {
            method: 'post',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },

      getUmuBenchmarksByShareClassesPaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/umu-benchmarks/by-securities`, {
            method: 'post',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },

      updateUmuBenchmark(umuBenchmarkId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/update-umu-benchmark/${umuBenchmarkId}`, {
            method: 'patch',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        });
      },

      getUmuSocialMediaSentimentsPaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/umu-social-media-sentiment`, {
            method: 'post',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },

      getSettingsPaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/settings`, {
            method: "post",
            data: data,
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err.response.data.code);
            });
        });
      },

      // Update setting function
      editSetting(settingId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/settings/${settingId}`, {
            method: 'patch',
            data: data
          })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err.response.data.message);
          });
        });
      },
      
    }
  }
</script>
