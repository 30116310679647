<template>
  <div class="master-filter" ref="filtersContainer" v-show="localFiltersDisplayed">
    <div class="filters-title">Master Filter</div>
    <div class="filters-container">
      <div class="checkboxes-wrapper">
        <div class="checkbox-container" v-for="(checkbox, index) in filters.checkboxes" :key="index">
          <div @mouseover="localActiveFilterIndex = checkbox.tableName" @mouseout="localActiveFilterIndex = -1">
            <input :id="index" type="checkbox" :indeterminate="checkbox.value === null" :checked="checkbox.value" @change="checkBoxChangeHandler(checkbox)"/>
            <label :for="index">{{ checkbox.name }}</label>
          </div>
        </div>
      </div>
      <div class="dropdowns-wrapper">
        <div class="dropdown-container" v-for="(dropdown, index) in filters.dropdowns" :key="index">
          <div v-if="dropdown.type === 'dateRange'" @mouseover="localActiveFilterIndex = dropdown.tableName" @mouseout="localActiveFilterIndex = -1">
            <label>{{ dropdown.label }}</label>
            <DatePicker
              v-model="dropdown.selectedOptions"
              range
              format="yyyy-mm-dd"
              :placeholder="dropdown.placeholder"
              class="datepicker-input"
            />
          </div>
          <div v-else @mouseover="localActiveFilterIndex = dropdown.tableName" @mouseout="localActiveFilterIndex = -1">
            <multiselect
                v-model="dropdown.selectedOptions"
                :options="dropdown.dropdownOptions"
                :label="dropdown.label"
                :placeholder="dropdown.placeholder"
                :multiple="dropdown.multiselect"
                track-by="id"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="filter-buttons">
        <button class="apply-filter" @click="applyFilters">Apply Filters</button>
        <button class="reset-filter" @click="resetFilters">Reset Filters</button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "MasterFilter",
  components: {
    Multiselect,
    DatePicker,
  },
  props: {
    filters: Object,
    filtersDisplayed: Boolean,
    activeFilterIndex: Number,
  },
  data() {
    return {
      localActiveFilterIndex: this.activeFilterIndex,
      localFiltersDisplayed: this.filtersDisplayed,
    };
  },
  watch: {
    activeFilterIndex(newVal) {
      this.localActiveFilterIndex = newVal;
    },
    localActiveFilterIndex(newVal) {
      this.$emit("update:activeFilterIndex", newVal);
    },
    filtersDisplayed(newVal) {
      this.localFiltersDisplayed = newVal;
    }
  },
  methods: {
    checkBoxChangeHandler(checkbox) {
      if (checkbox.indeterminate) {
        switch (checkbox.value) {
          case null:
            checkbox.value = true;
            break;
          case true:
            checkbox.value = false;
            break;
          case false:
            checkbox.value = null;
            break;
        }
      } else {
        switch (checkbox.value) {
          case true:
            checkbox.value = false;
            break;
          case false:
            checkbox.value = true;
            break;
        }
      }
    },
    applyFilters() {
      this.$emit("apply-filters");
    },
    resetFilters() {
      this.$emit("reset-filters");
    },
  },

  updated() {
    // Find the target element with class "table-actions" (Coming from DataTableHolder -> DataTable)
    const tableActionsElement = document.querySelector('.datatable-container .table-actions');
    const filtersContainer = this.$refs.filtersContainer;

    // Move the filters container after the tableActionsElement
    if (tableActionsElement && filtersContainer && !this.filtersDisplayed) {
      tableActionsElement.parentNode.insertBefore(filtersContainer, tableActionsElement.nextSibling);
      this.localFiltersDisplayed = true;
    }
  }
};
</script>

<style scoped>
.master-filter {
  margin: 2% 0%;
}
.filters-title {
  font-size: 20px;
  font-weight: 400;
}
.filters-container {
  width: 40%;
  background-color: white;
  padding: 1.5% 1%;
  margin: 0px 0px 1.5% 0px;
}
.checkboxes-wrapper {
  border-bottom: 1px solid gray;
}
.checkboxes-wrapper,
.dropdowns-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.dropdowns-wrapper {
  justify-content: space-between;
}
.checkbox-container,
.dropdown-container {
  margin-right: 20px;
  margin-bottom: 10px;
}
.dropdown-container .multiselect {
  width: 300px;
  cursor: pointer;
}
.filter-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.filter-buttons button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}
.filter-buttons button.apply-filter {
  background-color: #3c6e71;
  color: #fff;
}
.filter-buttons button.reset-filter {
  background-color: #a31111;
  color: #fff;
}
/* Change default checkbox behaviour and visualization */
.checkbox-container input[type="checkbox"] {
  display: none;
}
.checkbox-container input[type="checkbox"] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.checkbox-container input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  vertical-align: -20%;
  height: 2ex;
  width: 2ex;
  background-color: white;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
  margin-right: 0.5em;
}
.checkbox-container input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  width: 1.2ex;
  height: 0.4ex;
  background: rgba(0, 0, 0, 0);
  top: 0.9ex;
  left: 0.4ex;
  border: 3px solid rgb(2, 124, 2);
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.filters-container .checkbox-container input[type="checkbox"]:not(:checked):not(:indeterminate) + label::after {
  display: block;
  content: "✕";
  position: absolute;
  top: 0.65em;
  left: 0.1em;
  font-size: 1.2em;
  color: #b60f0f;
  line-height: 0;
  font-weight: bold;
  transition: all 0.2s;
}
/* MasterFilter.vue or main CSS file */
.dropdown-container .datepicker-input {
  width: 280px; /* Increase width as needed */
  padding: 10px; /* Optional: Adjust padding for more readability */
  font-size: 16px; /* Optional: Increase font size for visibility */
}

.dropdown-container label {
  margin-right: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>