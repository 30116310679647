<template>
    <popup
      :title="'Edit Setting ' + setting.type"
      @close="$emit('close')"
    >
        <template v-slot:content>

            <!-- TODO for organization_banner -->
            <div v-if="setting.type === 'organization_banner'">

                <!-- English Banner Fields -->
                <div class="field">
                    <label>EN - Text:</label>
                    <input type="text" v-model="enBannerText" placeholder="Banner text message" />

                    <label>EN - Link:</label>
                    <input type="text" v-model="enBannerLink" placeholder="Link URL" />

                    <label>EN - Link Text:</label>
                    <input type="text" v-model="enBannerLinkText" placeholder="Link display text" />
                </div>

                <!-- German Banner Fields -->
                <div class="field">
                    <label>DE - Text:</label>
                    <input type="text" v-model="deBannerText" placeholder="Banner text message DE" />

                    <label>DE - Link:</label>
                    <input type="text" v-model="deBannerLink" placeholder="Link URL DE" />

                    <label>DE - Link Text:</label>
                    <input type="text" v-model="deBannerLinkText" placeholder="Link display text DE" />
                </div>

                <!-- Is Active Toggle -->
                <div class="field">
                    <label class="custom-check green">
                    <input type="checkbox" id="isActive" v-model="isActive">
                    <span class="checkmark"></span>
                    Is Active
                    </label>
                </div>

                <!-- Expires On -->
                <div class="field">
                    <label for="expiresOn">Expires On:</label>
                    <input type="date" id="expiresOn" v-model="expiresOn" />
                </div>
                

                <button class="transition save-btn" @click="updateSettingHandler()" :disabled="updateLoading">
                    <template v-if="!updateLoading">Save</template>
                    <font-awesome-icon v-else icon="spinner" spin />
                </button>

            </div>




            <div v-else-if="setting.type === 'featured_asset_manager'">

                <!-- Partner Dropdown -->
                <div class="field">
                    <label for="partner">Partner:</label>
                    <select id="partner" v-model="selectedPartnerId">
                    <option v-for="partner in partners" :key="partner.id" :value="partner.id">
                        {{ partner.name }}
                    </option>
                    </select>
                </div>

                <!-- Is Active Toggle -->
                <div class="field">
                    <label class="custom-check green">
                        <input type="checkbox" id="isActive" v-model="isActive">
                        <span class="checkmark"></span>
                        Is Active
                    </label>
                </div>
    
                <!-- Expires On -->
                <div class="field">
                    <label for="expiresOn">Expires On:</label>
                    <input type="date" id="expiresOn" v-model="expiresOn" />
                </div>

                <button class="transition save-btn" @click="updateSettingHandler()" :disabled="updateLoading">
                    <template v-if="!updateLoading">Save</template>
                    <font-awesome-icon v-else icon="spinner" spin />
                </button>

            </div>




            <div v-else-if="setting.type === 'home_slider'">

                <!-- Partner Dropdown -->
                <div class="field">
                    <label for="article">Article:</label>
                    <select id="article" v-model="selectedArticleId">
                    <option v-for="article in articles" :key="article.id" :value="article.id">
                        {{ article.title }} ({{ article.organization }})
                    </option>
                    </select>
                </div>

                <!-- Is Active Toggle -->
                <div class="field">
                    <label class="custom-check green">
                        <input type="checkbox" id="isActive" v-model="isActive">
                        <span class="checkmark"></span>
                        Is Active
                    </label>
                </div>
    
                <!-- Expires On -->
                <div class="field">
                    <label for="expiresOn">Expires On:</label>
                    <input type="date" id="expiresOn" v-model="expiresOn" />
                </div>


                <button class="transition save-btn" @click="updateSettingHandler()" :disabled="updateLoading">
                    <template v-if="!updateLoading">Save</template>
                    <font-awesome-icon v-else icon="spinner" spin />
                </button>

            </div>




            <!-- Display search input and dropdown for most_visited_equity type -->
            <div v-else-if="setting.type === 'most_visited_equity'">

                <!-- Display current selected security -->
                <div class="field">
                    <label for="currentSecurity">Current Security:</label>
                    <input type="text" id="currentSecurity" v-model="securityName" readonly/>
                </div>

                <!-- Security Search Input -->
                <div class="field">
                    <label for="search">Search Equity (Name or ISIN):</label>
                    <input type="text" id="search" v-model="searchTerm" @keyup.enter="searchEquity" placeholder="Enter at least 3 characters"/>
                    <div v-if="searchTerm.length > 0 && searchTerm.length < 3" class="warning-message">Please enter at least 3 characters.</div>
                    <div v-else-if="searchTerm.length >= 3" class="info-message">Press Enter to search.</div>
                </div>

                <!-- Security Dropdown: only show if securities are available after search -->
                <div v-if="securities.length > 0" class="field">
                    <label for="security">Select Security:</label>
                    <select id="security" v-model="selectedSecurityId" @change="updateSelectedSecurity">
                        <option v-for="security in securities" :key="security.id" :value="security.id">
                            {{ security.name }} ({{ security.isin }})
                        </option>
                    </select>
                </div>

                <!-- Is Active Toggle -->
                <div class="field">
                    <label class="custom-check green">
                        <input type="checkbox" id="isActive" v-model="isActive">
                        <span class="checkmark"></span>
                        Is Active
                    </label>
                </div>

                <!-- Expires On -->
                <div class="field">
                    <label for="expiresOn">Expires On:</label>
                    <input type="date" id="expiresOn" v-model="expiresOn" />
                </div>

                <!-- Loading and No Results Messages -->
                <div v-if="loadingSecurities">Loading securities...</div>

                <button class="transition save-btn" @click="updateSettingHandler()" :disabled="updateLoading || !selectedSecurityId">
                    <template v-if="!updateLoading">Save</template>
                    <font-awesome-icon v-else icon="spinner" spin />
                </button>
                
            </div>



            
            <!-- Edit fields for masterclass_beginner or masterclass_advanced -->
            <div v-else-if="['masterclass_beginner', 'masterclass_advanced'].includes(setting.type)">


                <!-- Masterclass Title -->
                <div class="field">
                    <label for="masterclassTitle">Title:</label>
                    <input type="text" id="masterclassTitle" v-model="masterclassTitle" />
                </div>

                <!-- Masterclass Date -->
                <div class="field">
                    <label for="masterclassDate">Date:</label>
                    <input type="date" id="masterclassDate" v-model="masterclassDate" />
                </div>

                <!-- Tag ID Dropdown -->
                <div class="field">
                    <label for="tagId">Tag:</label>
                    <select id="tagId" v-model="selectedTagId">
                        <option v-for="tag in tags" :key="tag.id" :value="tag.id">
                            {{ tag.name }}
                        </option>
                    </select>
                </div>

                <!-- Is Active Toggle -->
                <div class="field">
                    <label class="custom-check green">
                        <input type="checkbox" id="isActive" v-model="isActive">
                        <span class="checkmark"></span>
                        Is Active
                    </label>
                </div>

                <!-- Expires On -->
                <div class="field">
                    <label for="expiresOn">Expires On:</label>
                    <input type="date" id="expiresOn" v-model="expiresOn" />
                </div>

                <button class="transition save-btn" @click="updateSettingHandler()" :disabled="updateLoading">
                    <template v-if="!updateLoading">Save</template>
                    <font-awesome-icon v-else icon="spinner" spin />
                </button>

            </div>




        </template>
    </popup>
</template>
  
<script>
    import Popup from "@/components/shared/Popup.vue";
    import axios from 'axios';

    export default {
        name: 'EditSetting',
        props: {
            setting: Object
        },
        components: {
            Popup
        },
        data() {
            return {
                partners: [],
                selectedPartnerId: this.setting.partner_id || null,
                articles: [],
                selectedArticleId: this.setting.article_id || null,
                securities: [],
                securityId: this.setting.security_id || null,
                securityName: this.setting.security_name || null,
                selectedSecurityId: null,
                searchTerm: '',
                tags: [],
                selectedTagId: this.setting.options?.tagId || null,
                isActive: this.setting.is_active || false,
                expiresOn: this.setting.expires_on ? this.formatDate(this.setting.expires_on) : null,
                masterclassTitle: this.setting.options?.title || '',
                masterclassDate: this.setting.options?.date ? this.formatDate(this.setting.options.date) : null,
                updateLoading: false,
                loadingSecurities: false,
                enBannerText: this.setting.options?.en?.text || '',
                enBannerLink: this.setting.options?.en?.link || '',
                enBannerLinkText: this.setting.options?.en?.linkText || '',
                deBannerText: this.setting.options?.de?.text || '',
                deBannerLink: this.setting.options?.de?.link || '',
                deBannerLinkText: this.setting.options?.de?.linkText || '',

            };
        },
        methods: {

            // **Fetch articles from backend**
            fetchArticles() {
                axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/articles`)
                    .then(res => {
                        this.articles = res.data.map(article => ({
                            id: article.article_id,
                            title: article.article_title,
                            organization: article.organization_name
                        }));
                    })
                    .catch(err => {
                        console.error("Error fetching articles:", err);
                    });
            },

            // **Fetch tags from backend and populate availableTags**
            fetchTags() {
                axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/tags`)
                    .then(res => {
                        this.tags = res.data.tags.map(tag => ({
                            id: tag.tagId,
                            name: tag.name
                        }));
                    })
                    .catch(err => {
                        console.error("Error fetching tags:", err);
                    });
            },

            // Fetch partners when the component is created
            fetchPartners() {
                axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/partners`)
                    .then(res => {
                        this.partners = res.data;
                    })
                    .catch(err => {
                        console.error("Error fetching partners:", err);
                    });
            },

            // Send POST request to fetch securities based on search term or ISIN
            searchSecurities() {

                this.loadingSecurities = true;

                axios.post(`${this.$store.state.global.apiUrl}/backoffice/settings/search-securities`, {
                    searchTerm: this.searchTerm // Send the search term
                })
                .then(res => {
                    this.securities = res.data; // Populate dropdown options with the search results
                    this.loadingSecurities = false;
                })
                .catch(err => {
                    console.error("Error searching securities:", err);
                    this.loadingSecurities = false;
                });
            },

            // Trigger search only on Enter key press
            searchEquity() {
                if (this.searchTerm.length >= 3) {
                    this.searchSecurities();
                }
            },
            



            // Handle updating the setting
            updateSettingHandler() {
                this.updateLoading = true;

                const dataToSend = {};

                // Check and only include changed fields for partner
                dataToSend.organizationId = this.selectedPartnerId !== this.setting.partner_id
                    ? this.selectedPartnerId
                    : null;

                // Check and only include changed fields for security
                dataToSend.securityId = this.selectedSecurityId !== this.setting.security_id
                    ? this.selectedSecurityId
                    : null;

                // Check and only include changed fields for security
                dataToSend.articleId = this.selectedArticleId !== this.setting.article_id
                    ? this.selectedArticleId
                    : null;

                // Check if expiresOn is changed or cleared
                // Send an empty string if the field is cleared to allow setting it to null
                dataToSend.expiresOn = this.expiresOn === '' || this.expiresOn === null
                    ? ''
                    : (this.expiresOn !== this.formatDate(this.setting.expires_on) ? this.expiresOn : null);

                // Check if isActive changed
                dataToSend.isActive = this.isActive !== this.setting.is_active
                    ? this.isActive
                    : null;

                // For the options field (for masterclass_beginner or masterclass_advanced)
                if (['masterclass_beginner', 'masterclass_advanced'].includes(this.setting.type)) {
                    const masterclassOptionsToUpdate = {};

                    if (this.masterclassTitle !== this.setting.options?.title) {
                        masterclassOptionsToUpdate.title = this.masterclassTitle;
                    }
                    if (this.masterclassDate !== this.formatDate(this.setting.options?.date)) {
                        masterclassOptionsToUpdate.date = this.masterclassDate || null;
                    }
                    if (this.selectedTagId !== this.setting.options?.tagId) {
                        masterclassOptionsToUpdate.tagId = this.selectedTagId;
                    }

                    if (Object.keys(masterclassOptionsToUpdate).length > 0) {
                        dataToSend.masterclassOptions = masterclassOptionsToUpdate;
                    }
                }

                // For the banner options field (for organization_banner)
                if (this.setting.type === 'organization_banner') {
                    const bannerOptionsToUpdate = {};

                    // Check and add only changed fields for English banner options
                    if (this.enBannerText !== this.setting.options?.en?.text) {
                        bannerOptionsToUpdate.en = bannerOptionsToUpdate.en || {};
                        bannerOptionsToUpdate.en.text = this.enBannerText;
                    }
                    if (this.enBannerLink !== this.setting.options?.en?.link) {
                        bannerOptionsToUpdate.en = bannerOptionsToUpdate.en || {};
                        bannerOptionsToUpdate.en.link = this.enBannerLink;
                    }
                    if (this.enBannerLinkText !== this.setting.options?.en?.linkText) {
                        bannerOptionsToUpdate.en = bannerOptionsToUpdate.en || {};
                        bannerOptionsToUpdate.en.linkText = this.enBannerLinkText;
                    }

                    // Check and add only changed fields for German banner options
                    if (this.deBannerText !== this.setting.options?.de?.text) {
                        bannerOptionsToUpdate.de = bannerOptionsToUpdate.de || {};
                        bannerOptionsToUpdate.de.text = this.deBannerText;
                    }
                    if (this.deBannerLink !== this.setting.options?.de?.link) {
                        bannerOptionsToUpdate.de = bannerOptionsToUpdate.de || {};
                        bannerOptionsToUpdate.de.link = this.deBannerLink;
                    }
                    if (this.deBannerLinkText !== this.setting.options?.de?.linkText) {
                        bannerOptionsToUpdate.de = bannerOptionsToUpdate.de || {};
                        bannerOptionsToUpdate.de.linkText = this.deBannerLinkText;
                    }

                    // Only add bannerOptionsToUpdate to dataToSend if there are any changes
                    if (Object.keys(bannerOptionsToUpdate).length > 0) {
                        dataToSend.bannerOptions = bannerOptionsToUpdate;
                    }
                }

                // Send the PATCH request
                axios.patch(`${this.$store.state.global.apiUrl}/backoffice/settings/${this.setting.setting_id}`, dataToSend)
                    .then(() => {
                        this.updateLoading = false;
                        this.$emit('updated');
                    })
                    .catch(err => {
                        this.updateLoading = false;
                        console.error("Error updating setting:", err);
                    });
            },

            // Update selected security name when an option is chosen from the dropdown
            updateSelectedSecurity() {
                const selectedSecurity = this.securities.find(security => security.id === this.selectedSecurityId);
                this.securityName = selectedSecurity ? selectedSecurity.name : 'No Security Selected'; // Update the displayed security name
            },




            // Helper function to format date without time zone issues
            formatDate(dateStr) {
                const date = new Date(dateStr);
                // Get the date components
                const year = date.getFullYear();
                const month = (`0${date.getMonth() + 1}`).slice(-2); // Month is zero-based
                const day = (`0${date.getDate()}`).slice(-2);
                // Return the formatted date as yyyy-mm-dd
                return `${year}-${month}-${day}`;
            }
        },



        mounted() {
            if (this.setting.type === 'home_slider') {
                this.fetchArticles();
            }
            if (this.setting.type === 'featured_asset_manager') {
                this.fetchPartners();
            }
            if (this.setting.type === 'masterclass_beginner' || this.setting.type === 'masterclass_advanced') {
                this.fetchTags();
            }
        }
    };
</script>
  
  <style scoped>
  .field {
    margin-bottom: 20px;
  }
  
  .field label {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
  }
  
  .field input,
  .field select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  .save-btn {
    background: #3c6e71;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-btn:hover {
    background: #295456;
  }
  
  .save-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  </style>