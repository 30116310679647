<template>
    <div class="settings-page">
        <h1>
            Settings<small v-if="total"> ({{ total }})</small>
        </h1>

        <!-- Table with results -->
        <DataTableHolder
            :columns="columns"
            :column-search="false"
            :rows="rows"
            :totalPages="pages"
            :updateResults="updateResults"
            :actions="['edit', 'new']"
            @update-table="getSettingsHandler"
            @edit="editHandler"
            @add-new="showAddPopup=true"
        />

        <!-- Edit Setting Popup -->
        <EditSetting
            v-if="showEditPopup"
            :setting="editItem"
            @close="closeEditPopup"
            @updated="handleSettingUpdated"
        />

        <!-- Add Setting Popup -->
        <NewSetting
            v-if="showAddPopup"
            @close="closeAddPopup"
            @created="handleSettingCreated"
        />


    </div>
</template>
  
<script>
    import DataTableHolder from "@/components/shared/datatable/DataTableHolder.vue";
    import systemMixin from "@/mixins/system.vue";
    import EditSetting from "./popups/EditSetting.vue";
    import NewSetting from "./popups/NewSetting.vue";

    export default {
        name: "Settings",
        mixins: [systemMixin],
        components: {
            DataTableHolder,
            EditSetting,
            NewSetting,
        },
        data() {
            return {
                rows: null,
                columns: [
                    {
                        title: "ID",
                        name: "settingId",
                        sortable: true,
                    },
                    {
                        title: "Context",
                        name: "context",
                        sortable: true,
                    },
                    {
                        title: "Type",
                        name: "type",
                        sortable: true,
                    },
                    {
                        title: "Article ID",
                        name: "articleId",
                        sortable: true,
                    },
                    {
                        title: "Article Title",
                        name: "articleTitle",
                        sortable: true,
                    },
                    {
                        title: "Partner ID",
                        name: "partnerId",
                        sortable: true,
                        // hidden: true,
                    },
                    {
                        title: "Partner Name",
                        name: "partnerName",
                        sortable: true,
                        // hidden: true,
                    },
                    {
                        title: "Security ID",
                        name: "securityId",
                        sortable: true,
                        // hidden: true,
                    },
                    {
                        title: "Security Name",
                        name: "securityName",
                        sortable: true,
                        // hidden: true,
                    },
                    {
                        title: "Is Active",
                        name: "isActive",
                        sortable: true,
                    },
                    {
                        title: "Expires On",
                        name: "expiresOn",
                        sortable: true,
                    },
                ],
                editItem: null,
                pages: 0,
                updateResults: true,
                total: null,
                fullData: null,
                showEditPopup: false,
                showAddPopup: false,
            };
        },

        methods: {
            /**
             * Fetch settings data from backend API
             */
            getSettingsHandler(data) {
            this.getSettingsPaginated(data)
                .then((res) => {
                    this.pages = res.pagesCount;
                    let resultsFormatted = [];

                    for (const result of res.results) {
                        let resultObj = {
                            settingId: result.setting_id,
                            context: result.context,
                            type: result.type,
                            articleId: result.article_id,
                            articleTitle: result.article_title,
                            partnerId: result.partner_id,
                            partnerName: result.partner_name,
                            securityId: result.security_id,
                            securityName: result.security_name,
                            isActive: result.is_active,
                            expiresOn: result.expires_on,
                        };
                        resultsFormatted.push(resultObj);
                    }
                    this.rows = resultsFormatted;
                    this.fullData = res.results;
                    this.total = res.resultsCount;
                })
                .catch((err) => {
                    if (err === 401) {
                        this.logout();
                    }
                });
            },

            // Handles edit action for a row and opens the EditSetting popup
            editHandler(item) {
                this.editItem = this.fullData.find((result) => {
                    return result.setting_id === item.settingId;
                });
                this.showEditPopup = true; // Show the EditSetting popup
            },
            // Closes the EditSetting popup
            closeEditPopup() {
                this.showEditPopup = false;
            },
            // Handles the update event from EditSetting.vue
            handleSettingUpdated() {
                this.showEditPopup = false;
                this.updateResults = !this.updateResults; // Trigger the table to refresh
            },
            closeAddPopup() {
                this.showAddPopup = false;
            },
            handleSettingCreated() {
                this.showAddPopup = false;
                this.updateResults = !this.updateResults; // Refresh the data table
            },
        },
    };
</script>

<style scoped>
.settings-page {
margin: 20px;
}

h1 {
font-size: 24px;
font-weight: 600;
margin-bottom: 20px;
}

small {
font-size: 16px;
color: #888;
}

.date-filter {
display: flex;
align-items: center;
}

.field {
margin: 15px 0 30px;
display: flex;
align-items: center;
}

.field label:not(.custom-check) {
color: #44444f;
font-size: 16px;
font-weight: 500;
width: 100px;
}

.field input,
.field :deep(input) {
width: 200px;
height: auto;
box-shadow: none;
line-height: initial;
background: #fafafb;
border: 1px solid #44444f;
border-radius: 10px;
padding: 12px 15px;
margin: 0 10px;
outline: none;
font-size: 16px;
color: #171725;
}

.field input:focus,
.field :deep(input:focus) {
border-color: #3c6e71;
}

.field button {
background: #3c6e71;
color: #fff;
font-weight: 500;
font-size: 18px;
width: 100px;
height: 51px;
border: 0;
border-radius: 8px;
cursor: pointer;
outline: none;
}

.field button:hover {
background: #fff;
color: #3c6e71;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
</style>