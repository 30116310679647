<template>
  <div class="subscriptions-page">
    <h1>Subscriptions <small v-if="total">({{total}})</small></h1>

    <MasterFilter
        :filters="filters"
        :filtersDisplayed="filtersDisplayed"
        :activeFilterIndex="activeFilterIndex"
        @apply-filters="applyFilters"
        @reset-filters="resetFilters"
    />

    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getSubscriptionsHandler"
      @edit="editHandler"
    />

    <EditSubscription
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import usersMixin from '@/mixins/users'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditSubscription from './popups/EditSubscription'
  import MasterFilter from "@/components/shared/filters/MasterFilter.vue";

  export default {
    name: 'Subscriptions',
    mixins: [usersMixin, userMixin],
    components: {
      MasterFilter,
      DataTableHolder,
      EditSubscription
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'User ID',
            name: 'userId',
            sortable: true
          },
          {
            title: 'Name',
            name: 'firstName',
            sortable: true
          },
          {
            title: 'Email',
            name: 'email',
            sortable: false
          },
          {
            title: 'Paid Plan',
            name: 'paidPlan',
            sortable: true
          },
          {
            title: 'Actual Plan',
            name: 'actualPlan',
            sortable: true
          },
          {
            title: 'Future Plan',
            name: 'futurePlan',
            sortable: true
          },
          {
            title: 'Future Plan Added On',
            name: 'futurePlanAddedOn',
            sortable: true
          },
          {
            title: 'Billing Period',
            name: 'billingPeriod',
            sortable: true
          },
          {
            title: 'Next Payment Date',
            name: 'nextPaymentDate',
            sortable: true
          },
          {
            title: 'Last Payment Date',
            name: 'lastPaymentDate',
            sortable: true
          },
          {
            title: 'Last Payment Amount',
            name: 'lastPaymentAmount',
            sortable: true
          },
          {
            title: 'Last Payment Amount Net',
            name: 'lastPaymentAmountNet',
            sortable: true
          },
          {
            title: 'Free Upgrade Expiration Date',
            name: 'freeUpgradeExpirationDate',
            sortable: true
          },
          {
            title: 'Free Upgrade Assigned By',
            name: 'freeUpgradeAssignedBy',
            sortable: true
          },
          {
            title: 'Trial Expired',
            name: 'trialExpired',
            sortable: true
          },
        ],
        editItem: null,
        pages: 0,
        updateResults: false,
        total: null,
        fullData: null,
        filters: {
          checkboxes: {
            isTesting: {
              name: "Testing",
              value: false,
              indeterminate: false,
            }
          },
          dropdowns: {
            actualPlan: {
              selectedOptions: [],
              dropdownOptions: [
                { id: 0, name: "Explore" },
                { id: 1, name: "Learn" },
                { id: 2, name: "Grow" }
              ],
              label: 'name',
              placeholder: "Select Actual Plans",
              multiselect: true,
              tableName: 'actualPlan'
            }
          }
        },
        filtersDisplayed: true,
        activeFilterIndex: -1,
        filtersToSend: {},
      }
    },
    methods: {
      // * Get subscriptions from API
      getSubscriptionsHandler(data) {
        const newData = { ...data, filter: this.filtersToSend};
        this.getSubscriptions(newData)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let subscriptionsFormatted = [];
            // * Loop through subscriptions and add only the needed properties
            for (const row of res.userRows) {
              let userObj = this.constructSubscriptionRow(row);
              // * Add formatted user to formatted subscriptions array
              subscriptionsFormatted.push(userObj);
            }
            // * Assign table rows to be the formatted subscriptions array
            this.rows = subscriptionsFormatted;
            this.fullData = res.userRows;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {   
              this.logout();
            }
          })
      },
      constructSubscriptionRow(subscription) {
        // * Construct the subscription in order
        let subscriptionRow = {
          id: subscription.id,
          userId: subscription.userId,
          firstName: `${subscription.firstName} ${subscription.lastName}`,
          email: subscription.email,
          paidPlan: subscription.paidPlan,
          actualPlan: subscription.actualPlan,
          futurePlan: subscription.futurePlan,
          futurePlanAddedOn: subscription.futurePlanAddedOn,
          billingPeriod: subscription.billingPeriod,
          nextPaymentDate: subscription.nextPaymentDate,
          lastPaymentDate: subscription.lastPaymentDate,
          lastPaymentAmount: subscription.lastPaymentAmount,
          lastPaymentAmountNet: subscription.lastPaymentAmountNet,
          freeUpgradeExpirationDate: subscription.freeUpgradeExpirationDate,
          freeUpgradeAssignedBy: subscription.freeUpgradeAssignedBy,
          trialExpired: subscription.trialExpired,
        };

        // * Change paid, actual and future plans from numbers to text
        if (subscriptionRow.paidPlan == 0) subscriptionRow.paidPlan = 'Explore';
        else if (subscriptionRow.paidPlan == 1) subscriptionRow.paidPlan = 'Learn';
        else if (subscriptionRow.paidPlan == 2) subscriptionRow.paidPlan = 'Grow';

        if (subscriptionRow.actualPlan == 0) subscriptionRow.actualPlan = 'Explore';
        else if (subscriptionRow.actualPlan == 1) subscriptionRow.actualPlan = 'Learn';
        else if (subscriptionRow.actualPlan == 2) subscriptionRow.actualPlan = 'Grow';

        if (subscriptionRow.futurePlan == 0) subscriptionRow.futurePlan = 'Explore';
        else if (subscriptionRow.futurePlan == 1) subscriptionRow.futurePlan = 'Learn';
        else if (subscriptionRow.futurePlan == 2) subscriptionRow.futurePlan = 'Grow';
        
        // * Change billing period from numbers to text
        if (subscriptionRow.billingPeriod == 0) subscriptionRow.billingPeriod = 'Monthly';
        else if (subscriptionRow.billingPeriod == 1) subscriptionRow.billingPeriod = 'Annualy';

        return subscriptionRow;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(row => {
          return row.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;

        this.updateResults = !this.updateResults;
      },
      applyFilters() {
        let dropdowns = this.filters.dropdowns;
        let checkboxes = this.filters.checkboxes;
        this.filtersToSend = {};

        for (const key in dropdowns) {
          let dropdownSelectedOptions = dropdowns[key].selectedOptions;
          if (dropdownSelectedOptions && dropdownSelectedOptions.length > 0) {
            this.filtersToSend[key] = dropdownSelectedOptions.map(option => option.id);
          }
        }

        for (const key in checkboxes) {
          let checkBoxValue = checkboxes[key].value;
          if(checkBoxValue !== null) {
            this.filtersToSend[key] = checkBoxValue;
          }
        }

        this.updateResults = !this.updateResults;
      },
      resetFilters() {
        for (const key in this.filters.dropdowns) {
          this.filters.dropdowns[key].selectedOptions = [];
        }
        this.applyFilters();
      },

    },

    created() {
      this.applyFilters();
    },

  }
</script>

<style scoped>

</style>
