<template>
  <!-- DataTable component -->
  <div class="data-table relative">
    <div class="langs-tabs" v-if="langs">
      <a
        class="transition"
        :class="{active: selectedLanguage == 'en'}"
        @click="selectLanguage('en')"
      >
        <country-flag country='gb' size='small'/>
        <span class="name">
          English
        </span>
      </a>
      <a
        class="transition"
        :class="{active: selectedLanguage == 'de'}"
        @click="selectLanguage('de')"
      >
        <country-flag country='de' size='small'/>
        <span class="name">
          German
        </span>
      </a>
    </div>
    <div class="table-actions">
      <!-- Search Field -->
      <div class="search-field" v-if="pages > 0 || rows.length == 0">
        <label for="search">Search:</label>
        <input type="search" placeholder="Search..." v-model="searchTerm" @keyup="enterSearch($event)">
        <button class="search-btn transition" @click="searchHandler()">Search</button>
      </div>
      <!-- Items per page select -->
      <div class="items-per-page" v-if="pages > 0 || rows.length == 0">
        <h5>Items Per Page:</h5>
        <div class="custom-select" :class="{open: pageSelect}" @click="pageSelect = !pageSelect" v-click-away="pageAway">
          <span>{{itemsPerPage}}</span>
          <div class="select-dropdown" v-if="pageSelect">
            <a @click="selectPageItems(50)">50</a>
            <a @click="selectPageItems(100)">100</a>
            <a @click="selectPageItems(200)">200</a>
            <a @click="selectPageItems(500)">500</a> <!-- Dropdown content -->

          </div>
        </div>
      </div>
    </div>

    <div class="new-item" v-if="actions.includes('new')">
      <button class="transition" @click="$emit('add-new')">
        <img class="transition" :src="$s3Url + 'svgs/icons/plus.svg'">
        <span>New</span>
      </button>
    </div>

    <div class="clear-col-search" v-if="columnSearch">
      <button class="green-btn transition" @click="clearColumnSearch()">Clear column search</button>
    </div>

    <template v-if="rows && rows.length > 0">
      
      <Pagination
        v-if="pages > 0"
        :currentPage="currentPage"
        :pages="pages"
        @go-to-page="goToPage"
      />

      <!-- Table -->
      <div class="table-holder">
        <table cellspacing="0">
          <!-- Headings row -->
          <thead :class="{'has-langs': langs}">
            <tr>
              <template v-for="(column, index) in columns">
                <th v-if="column.sortable" :key="index" :class="['sortable', column.customClass]" @click="sortHandler(column.name)">
                  <div class="th-content" :class="{'has-search': columnSearch}">
                    <span>{{column.title}}</span>
                    <div class="sorting-actions" :class="{active: sortBy.column == column.name}" v-if="column.sortable">
                      <template v-if="sortBy.column != column.name">
                        <font-awesome-icon icon="sort" />
                      </template>
                      <template v-else-if="sortBy.order == 'ASC'">
                        <font-awesome-icon icon="sort-up" />
                      </template>
                      <template v-else-if="sortBy.order == 'DESC'">
                        <font-awesome-icon icon="sort-down" />
                      </template>
                    </div>
                    <input v-if="columnSearch && !column.notSearchable" type="text" class="column-search-field" placeholder="Search..." @click.stop="" @keyup="searchByColumnInput(column.name, $event)" @blur="searchByColumn(column.name, $event)">
                  </div>
                </th>
                <th v-else :key="'else-' + index" :class="[column.customClass]">
                  <div class="th-content" :class="{'has-search': columnSearch}">
                    <span>{{column.title}}</span>
                    <input v-if="columnSearch" type="text" class="column-search-field" placeholder="Search..." @click.stop="" @blur="searchByColumn(column.name, $event)">
                  </div>
                </th>
              </template>
              <th v-if="actions && actions.length > 0">Actions</th>
            </tr>
          </thead>
          <!-- Data rows -->
          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <template v-for="(value, index) in row">
                <template v-if="index != 'hidden'">
                  <td v-if="(typeof value != 'object' || (typeof value == 'object' && value && value.date) || !value)" :key="index">
                    <!-- If value is true show check -->
                    <template v-if="value === true">
                      <font-awesome-icon class="check" icon="check" />
                    </template>
                    <!-- If value is false show cross (x) -->
                    <template v-else-if="value === false">
                      <font-awesome-icon class="cross" icon="times" />
                    </template>
                    <template v-else-if="typeof value == 'string' && value.startsWith('https://')">
                      <img :src="getMediaThumb(value, 'xs')" @error="noImage($event)">
                    </template>
                    <template v-else-if="index == 'profileImage'">
                      <img :src="$s3Url + 'placeholders/person-image.png'">
                    </template>
                    <template v-else-if="index.includes('s3Image')">
                      <img :src="$s3Url + value">
                    </template>
                    <template v-else-if="index.includes('image')">
                      <img :src="value">
                    </template>
                    <!-- If no value show dash (-) -->
                    <template v-else-if="value === undefined || value === null || value.length === 0 || value === 'n/a' || value === ' '">
                      <font-awesome-icon icon="minus" />
                    </template>

                    <!-- If value is date format it -->
                    <template v-else-if="value && value.date && page == 'Portfolio Orders' && index == 'addedOn'">
                      <span :class="thisWeekDate(value.date)">{{ $filters.formatDateTime(value.date) }}</span>
                    </template>
                    <template v-else-if="value && value.date && page == 'Portfolio Orders' && index == 'orderAdjustableUntil'">
                      <span :class="thisWeekDate(value.date)">{{ $filters.formatDateTime(value.date) }}</span>
                    </template>

                    <template v-else-if="value && value.date && page == 'Quiz Participants' && (index == 'createdOn' || index == 'submittedOn')">
                      <span>{{ $filters.formatDateTime(value.date) }}</span>
                    </template>
                    <!-- If value is date format it -->
                    <template v-else-if="value && value.date && (index.includes('sendDate') || index.includes('expirationDate'))">
                      <span :class="thisWeekDate(value.date)">{{ $filters.formatDateTime(value.date) }}</span>
                    </template>
                    
                    <template v-else-if="value && value.date">
                      <span :class="thisWeekDate(value.date)">{{ $filters.formatDate(value.date) }}</span>
                    </template>
                    <!-- If value is date format it -->
                    <template v-else-if="value && index.includes('Date')">
                      <span :class="thisWeekDate(value)">{{ $filters.formatDate(value) }}</span>
                    </template>
                    <!-- If value is date format it -->
                    <template v-else-if="value && (index.includes('startedAt') ||  index.includes('endedAt'))">
                      <span :class="thisWeekDate(value)">{{ $filters.formatDateTime(value) }}</span>
                    </template>
                    <template v-else-if="index.includes('nextRun') || index.includes('command')">
                      <a v-if="index.includes('command')" @click="$emit('edit', row)">
                        <span v-html="value"></span>
                      </a>
                      <span v-else v-html="value"></span>
                    </template>
                    <template v-else-if="(index && value) && index == 'notificationData'">
                      <button class="show-content-btn" @click="$emit('show-content-data', {id: row.id, notificationData: value})">
                        Preview
                      </button>
                    </template>
                    <template v-else>
                      <template v-if="index.includes('url') && !index.includes('name') && !index.includes('Name')">
                        <span class="lowercase">
                          {{value}}
                        </span>
                      </template>
                      <template v-else-if="(index.includes('name') || index.includes('Name') || index.includes('command') || index.includes('faq') || index.includes('benchmark') || index.includes('Benchmark')) && actions.includes('edit')">
                        <a @click="$emit('edit', row)">
                          <span v-if="searchTerm && searchTerm.length > 0 && searchEnabled"  v-html="highlightSearchTerm(value)">
                          </span>
                          <span v-else>
                            <span class="pre-line" v-if="index.includes('faq')" v-html="value"></span>
                            <template v-else><span v-html="value"></span></template>
                          </span>
                        </a>
                      </template>
                      <template v-else-if="index.includes('rg') && page == 'Fund suitabilities'">
                        <svg width="20" height="23" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.08315 4.9843L11.9924 1.35676H1.8527V0.857992C1.8527 0.618979 1.65907 0.425781 1.42049 0.425781C1.18191 0.425781 0.988281 0.618979 0.988281 0.857992V13.3921H1.8527V8.61228H11.9924L9.08315 4.9843Z"
                            :fill="flagColor(value)" />
                        </svg>
                      </template>
                      <template v-else-if="index == 'progressBar'">
                        <div class="progress-container">
                          <div class="progress-bar">
                            <div class="progress" :style="{ width: value + '%' }"></div>
                          </div>
                          <div class="progress-value">{{ value }}%</div>
                          <!-- <span class="progress-tooltip">{{ value }}%</span> -->
                        </div>
                      </template>
                      <template v-else>
                        <span
                          v-if="searchTerm && searchTerm.length > 0 && searchEnabled" 
                          v-html="highlightSearchTerm(value)"
                        >
                        </span>
                        <span v-else>
                          <template v-if="typeof value == 'string' && value.includes('gradient-text')">
                            <span v-html="value"></span>
                          </template>
                          <template v-else-if="typeof value === 'string' && value.includes('notification-text-column')">
                            <span v-html="value"></span>
                          </template>
                          <template v-else-if="typeof value == 'string' && value.includes('<span class=')">
                            <span class="statuses" v-html="value"></span>
                          </template>
                          <template v-else-if="typeof value == 'string' && value.includes('<span')">
                            <span v-html="value"></span>
                          </template>
                          <template v-else-if="typeof value == 'string' && value.length > 50">
                            {{value.slice(0, 50)}}...
                          </template>
                          <template v-else-if="value == 'Basic'">
                            <strong class="basic">Basic</strong>
                          </template>
                          <template v-else-if="value == 'Education'">
                            <strong class="education">Education</strong>
                          </template>
                          <template v-else-if="value == 'Premium'">
                            <strong class="premium">Premium</strong>
                          </template>
                          <template v-else>
                            {{value}}
                          </template>
                        </span>
                      </template>
                    </template>
                  </td>
                  <template v-else-if="(typeof value == 'object' && langs)">
                    <td v-for="(item, index2) in value[selectedLanguage]" :key="index + index2">
                      {{item}}
                    </td>
                  </template>
                  <template v-else>
                    <td :key="index">
                      <template v-if="(index && value) && index == 'notificationData'">
                        <button class="show-content-btn" @click="$emit('show-content-data', {id: row.id, notificationData: value})">
                          Preview
                        </button>
                      </template>
                      <template v-else>
                        {{value}}
                      </template>
                    </td>
                  </template>
                </template>
              </template>
              <!-- Row actions (edit, delete, etc.) -->
              <td class="actions" v-if="actions.length > 0">
                <a @click="$emit('view', row)" v-if="actions.includes('view')">
                  <font-awesome-icon icon="eye" />
                </a>
                <a @click="$emit('edit', row)" v-if="actions.includes('edit')">
                  <font-awesome-icon icon="pen" />
                </a>
                <a class="delete" @click="$emit('delete', row)" v-if="actions.includes('delete')">
                  <font-awesome-icon icon="trash" />
                </a>
                <a class="exportData"  @click="$emit('exportData', row)" v-if="actions.includes('exportData')">
                  <font-awesome-icon icon="file-export" />
                </a>
                <a class="view-answers" @click="$emit('view-answers', row)" v-if="actions.includes('answers')">
                  <font-awesome-icon icon="comment" />
                </a>
                <a class="clone" @click="$emit('clone', row)" v-if="actions.includes('clone')">
                  <font-awesome-icon icon="clone" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        v-if="pages > 0"
        :currentPage="currentPage"
        :pages="pages"
        @go-to-page="goToPage"
      />

    </template>
    <template v-else>
      <h5 class="no-results">No results found</h5>
    </template>

    <!-- Table loader (overlay spinner) -->
    <div class="data-table-loader" v-if="loading">
      <font-awesome-icon icon="spinner" spin />
    </div>
  </div>
</template>

<script>
  import mediaMixin from '@/mixins/media'

  import Pagination from './Pagination'

  export default {
    name: 'DataTable',
    mixins: [mediaMixin],
    components: {
      Pagination
    },
    props: {
      columns: Array,
      rows: Array,
      actions: Array,
      pages: Number,
      currentPage: Number,
      itemsPerPage: Number,
      sortBy: Object,
      loading: Boolean,
      page: String,
      langs: Boolean,
      columnSearch: Boolean
    },
    data() {
      return {
        searchTerm: null,
        pageSelect: false,
        searchEnabled: false,
        selectedLanguage: 'en'
      }
    },
    methods: {
      // * Select active language for the table contents
      selectLanguage(lang) {
        this.selectedLanguage = lang;
      },
      // * Triggered when user uses pagination or go to page input field
      goToPage(page) {
        this.goToPageNumber = null;
        this.$emit('go-to-page', page);
      },
      // * Triggered when user clicks outside of the items per page element
      pageAway() {
        this.pageSelect = false;
      },
      // * Triggered when user selects items per page count from the dropdown
      selectPageItems(items) {
        this.$emit('items-per-page', items);
      },
      // * Triggered when user clicks on the search button
      searchHandler() {
        // * Set search enabled to true if the term is active for the highlighting to work only when user has searched smth.
        if (this.searchTerm && this.searchTerm.length > 0) {
          this.searchEnabled = true;
        } else {
          this.searchEnabled = false;
        }

        this.$emit('search-button', this.searchTerm);
      },
      // * Triggered when user hits enter in the search input field
      enterSearch(event) {
        // * Check if key hit is enter and if is emit search event with term
        if (event.keyCode == 13) {
          // * Set search enabled to true if the term is active for the highlighting to work only when user has searched smth.
          if (this.searchTerm && this.searchTerm.length > 0) {
            this.searchEnabled = true;
          } else {
            this.searchEnabled = false;
          }

          this.$emit('search-enter', this.searchTerm);
        }
      },
      // * Triggered when user sorts by column
      sortHandler(column) {
        this.$emit('sorting', column);
      },
      // * Bold and underline text that matches search term
      highlightSearchTerm(text) {
        if (typeof text == 'string' && this.searchTerm && this.searchTerm.length > 0) {
          // Escape special characters in the search term
          const escapedSearchTerm = this.searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const searchPattern = new RegExp(`(${escapedSearchTerm})`, 'gi');
          const highlightedText = text.replace(searchPattern, '<span class="highlight">$1</span>');

          return highlightedText;
        } else {
          return text;
        }
      }
      ,
      noImage(event) {
        event.target.src = this.$s3Url + 'placeholders/person-image.png';
      },
      searchByColumn(columnName, event) {
        if (event.target.value && event.target.value.length > 0) {
          let dataToSend = {
            name: columnName,
            term: event.target.value
          };

          this.$emit('search-by-col', dataToSend);
        } else {
          let dataToSend = [];

          this.$emit('search-by-col', dataToSend);
        }
      },
      searchByColumnInput(columnName, event) {
        if (event.target.value && event.target.value.length > 0) {
          let dataToSend = {
            name: columnName,
            term: event.target.value
          };
          
          if (event.key == 'Enter') {
            this.$emit('search-by-col', dataToSend);
          }
        }
      },
      clearColumnSearch() {
        let inputs = Array.from(document.querySelectorAll('.column-search-field'));
        for (const input of inputs) {
          input.value = null;
        }
        
        this.$emit('clear-column-search');
      },
      // * Add class "red" to dates less than a week ago
      thisWeekDate(dateInput) {
        // * Compare dates
        const today = new Date();
        const date = new Date(dateInput);
        const diffTime = Math.abs(date - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // * If difference is less than 7 add class
        if (diffDays <= 7) {
          return 'red';
        } else {
          return '';
        }
      },
      flagColor(color) {
        if (color == 3) {
          return '#FC5A5A';
        } else if (color == 1) {
          return '#3DD598';
        } else if (color == 2) {
          return '#F5BF00';
        } else {
          return '#DFDFDF';
        }
      }
    }
  }
</script>

<style scoped>
  .hidden {
    display: none;
  }

  .progress-container {
    position: relative;
    min-width: 120px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
  }

  .progress-bar {
    position: relative;
    width: 100%;
    background-color: #858585;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
  }

  .progress {
    background-color: var(--dark-green);
    height: 100%;
    transition: width 0.3s ease-in-out;
  }

  .progress-value {
    position: absolute;
    color: white;
    left: 50%;
    transform: translateX(-50%);
  }

  .progress-tooltip {
    display: none;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    z-index: 3;
  }

  .progress-container:hover .progress-tooltip {
    display: block;
    word-break: keep-all !important;
  }

  .table-actions {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .langs-tabs {
    display: flex;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 75px;
    padding: 10px;
    background: #FAFAFB;
  }

  .langs-tabs a {
    display: flex;
    align-items: center;
    color: #44444f;
    font-size: 18px;
    padding: 5px 15px;
    margin-right: 20px;
    border-radius: 6px;
    cursor: pointer;
  }

  .langs-tabs a.active,
  .langs-tabs a:hover {
    background: #3c6e71;
    color: #FFF;
  }

  .langs-tabs a .name {
    margin-left: 10px;
  }

  .search-field {
    display: flex;
    align-items: center;
  }

  .search-field label {
    font-size: 18px;
    margin-right: 10px;
  }

  .search-field input {
    width: 400px;
    border: none;
    border-radius: 20px;
    padding: 9px 15px;
    font-size: 18px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    outline: none;
  }

  .search-field input[type="search"]::-webkit-search-decoration,
  .search-field input[type="search"]::-webkit-search-cancel-button,
  .search-field input[type="search"]::-webkit-search-results-button,
  .search-field input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  .search-field .search-btn {
    background: #3c6e71;
    color: #FFF;
    border: none;
    font-weight: 600;
    height: 45px;
    width: 100px;
    border-radius: 20px;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .search-field .search-btn:hover {
    background: #FFF;
    color: #3c6e71;
  }

  .items-per-page {
    display: flex;
    align-items: center;
  }

  .items-per-page h5 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    margin-right: 10px;
  }

  .new-item button {
    display: flex;
    align-items: center;
    background: #FFF;
    color: #3c6e71;
    border: 1px solid #3c6e71;
    font-weight: 600;
    border-radius: 6px;
    margin-top: 10px;
    padding: 5px 15px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .new-item button:hover {
    background: #3c6e71;
    color: #FFF;
  }

  .new-item button img {
    margin-right: 5px;
    filter: invert(41%) sepia(8%) saturate(2190%) hue-rotate(135deg) brightness(89%) contrast(82%);
  }

  .new-item button:hover img {
    filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(105%) contrast(104%);
  }

  .custom-select {
    border: 1px solid #E2E2EA;
    border-radius: 10px;
    padding: 0 20px;
    font-size: 18px;
    color: #000;
    font-weight: 600;
    width: 150px;
    height: 40px;
    outline: none;
    background: #FFF;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }

  .custom-select.open {
    border-color: #3c6e71;
  }
  
  .custom-select:after {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    color: #B5B5BE;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    right: 20px;
  }

  .clear-col-search button {
    padding: 0 20px;
  }

  .table-holder {
    width: 100%;
    margin-top: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    scrollbar-color: #3C6E71 transparent;
    scrollbar-width: thin;
  }

  .table-holder::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .table-holder::-webkit-scrollbar-track {
    background: #ececec;
  }

  .table-holder::-webkit-scrollbar-thumb {
    background: #3c6e71;
    border-radius: 3px;
  }

  .table-holder::-webkit-scrollbar-thumb:hover {
    background: #3c6e71;
  }

  table {
    border: 1px solid #E2E2EA;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
  }

  thead {
    position: sticky;
    top: 75px;
    z-index: 2;
  }

  thead.has-langs {
    top: 132px;
  }

  th {
    background: #FFF;
    color: #3C6E71;
    border-right: 1px solid #E2E2EA;
    border-bottom: 2px solid #3C6E71;
    font-weight: 600;
    text-align: left;
    padding: 10px;
    font-size: 17px;
  }

  th.sortable {
    cursor: pointer;
  }

  .th-content {
    display: flex;
    align-items: center;
  }

  .th-content.has-search {
    flex-wrap: wrap;
  }

  .sorting-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    visibility: hidden;
  }

  th.sortable:hover .sorting-actions,
  .sorting-actions.active {
    visibility: visible;
  }

  .column-search-field {
    flex: 0 0 100%;
    border: 1px solid #ddd;
    border-radius: 30px;
    padding: 5px 10px;
    width: 10px;
  }

  th:last-child,
  td:last-child {
    border-right: 0;
  }

  td {
    padding: 10px;
    border-right: 1px solid #E2E2EA;
    border-bottom: 1px solid #E2E2EA;
    background: #FFF;
    /* text-transform: capitalize; */
  }

  td:not(:first-child) span {
    word-break: break-word;
  }

  tbody tr:nth-child(even) td {
    background: #ececec;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tbody tr:hover td {
    background: #e0e0e0;
  }

  td .check {
    color: #3dd598;
  }

  td .cross {
    color: #fc5a5a;
  }

  td .red {
    color: #fc5a5a;
    font-weight: 600;
  }

  td a {
    cursor: pointer;
    color: #000;
  }

  td a:hover {
    text-decoration: underline;
  }

  td pre {
    margin: 0;
  }

  td :deep(.highlight) {
    font-weight: 600;
    text-decoration: underline;
  }

  td img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
  }
  
  .basic {
    color: rgb(60, 110, 113);
  }
  
  .premium {
    color: rgb(193, 161, 111);
  }
  
  .education {
    color: #003E52;
  }

  .statuses {
    display: flex;
    align-items: center;
  }

  .statuses :deep(span) {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    margin-right: 3px;
    position: relative;
    cursor: pointer;
  }

  .statuses :deep(span:hover) {
    border: 3px solid #333;
  }

  .statuses :deep(span:first-child) {
    width: 35px;
    height: 35px;
  }

  .statuses :deep(.gray) {
    background-color: rgb(165, 165, 165);
  }
  
  .statuses :deep(.red) {
    background-color: red;
  }
  
  .statuses :deep(.orange) {
    background-color: orange;
  }
  
  .statuses :deep(.green) {
    background-color: green;
  }

  .statuses :deep(.tooltip) {
    display: none;
    position: absolute;
    background: #FFF;
    top: 140%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    padding: 10px 15px;
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    border-radius: 6px;
    width: 300px;
  }

  .statuses :deep(span:hover .tooltip) {
    display: block;
  }

  .actions a {
    color: #3C6E71;
    cursor: pointer;
    margin-right: 8px;
  }

  .actions a.delete {
    color: #eb5757;
  }

  .actions a.exportData {
    color: #219a18;
  }

  .actions a svg {
    transition: all 0.15s ease-in-out;
  }

  .actions a:hover svg {
    transform: scale(1.3);
  }

  .data-table-loader {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.8);
    font-size: 100px;
    color: #3C6E71;
    z-index: 20;
  }

  .no-results {
    text-align: center;
    font-size: 30px;
    color: #9f9f9f;
  }

  .highlighted {
    transition: transform 0.3s ease;
    transform: scale(1.1);
    font-weight: bold;
  }
  
  .show-content-btn {
    background-color: #979797;
    color: black;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    margin: 5px 0;
    text-align: center;
  }

  .show-content-btn:hover {
    background-color: var(--dark-green);
  }

  @media (max-width: 1024px) {
    .table-holder {
      overflow: auto;
    }

    table {
      overflow: hidden;
    }

    thead {
      position: initial;
      top: unset;
    }
  }
</style>
