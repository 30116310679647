<template>
  <popup
    title="Edit subscription"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label>Actual Plan:</label>
        <div class="custom-select" :class="{open: actualPlanSelect}" @click="actualPlanSelect = !actualPlanSelect" v-click-away="actualPlanAway">
          <span v-if="actualPlan != null">{{plans[actualPlan]}}</span>
          <span v-else>Select Plan</span>
          <div class="select-dropdown" v-if="actualPlanSelect">
            <a
              v-for="(plan, index) in plans"
              :key="index"
              @click="actualPlan = index"
            >
              {{plan}}
            </a>
          </div>
        </div>
      </div>

      <div class="field">
        <label>Free Upgrade Expiration Date:</label>
        <div class="date-filter">
          <Datepicker class="datepicker" v-model="freeUpgradeExpirationDate" format="yyyy-MM-dd" :enable-time-picker="false" />
        </div>
      </div>

      <div class="period-text">For testing purposes only:</div>

      <div class="field">
        <label>Paid Plan:</label>
        <div class="custom-select" :class="{open: paidPlanSelect}" @click="paidPlanSelect = !paidPlanSelect" v-click-away="paidPlanAway">
          <span v-if="paidPlan != null">{{plans[paidPlan]}}</span>
          <span v-else>Select Plan</span>
          <div class="select-dropdown" v-if="paidPlanSelect">
            <a
              v-for="(plan, index) in plans"
              :key="index"
              @click="paidPlan = index"
            >
              {{plan}}
            </a>
          </div>
        </div>
      </div>

      <div class="field">
        <label>Future Plan:</label>
        <div class="custom-select" :class="{open: futurePlanSelect}" @click="futurePlanSelect = !futurePlanSelect" v-click-away="futurePlanAway">
          <span v-if="futurePlan != null">{{plans[futurePlan]}}</span>
          <span v-else>Select Plan</span>
          <div class="select-dropdown" v-if="futurePlanSelect">
            <a
              v-for="(plan, index) in plans"
              :key="index"
              @click="futurePlan = index"
            >
              {{plan}}
            </a>
          </div>
        </div>
      </div>

      <div class="field">
        <label>Period</label>
        <select class="period-select" v-model="billingPeriod">
          <option value="0">0</option>
          <option value="1">1</option>
        </select>
      </div>

      <div class="field">
        <label>Next Payment Date:</label>
        <Datepicker class="datepicker" v-model="nextPaymentDate" format="yyyy-MM-dd" :enable-time-picker="false" />
      </div>

      <div class="field">
        <label>Last Payment Date:</label>
        <Datepicker class="datepicker" v-model="lastPaymentDate" format="yyyy-MM-dd" :enable-time-picker="false" />
      </div>

      <div class="field trial-expired-wrapper">
        <label for="trial-expired">Trial Expired:</label>
        <label class="custom-check green checkbox">
          <input id="trial-expired" type="checkbox" v-model="trialExpired">
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="error-message" v-if="actualPlan < item.paidPlan">
        You can't downgrade a subscriber
      </div>

      <button
        class="transition save-btn"
        :disabled="actualPlan < item.paidPlan"
        @click="updateSubscriptionHandler()"
      >
        <span v-if="!buttonLoading">
          Update
        </span>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import usersMixin from '@/mixins/users'
  import datesMixin from '@/mixins/dates'
  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditSubscription',
    mixins: [usersMixin, datesMixin],
    components: {
      Popup
    },
    props: {
      item: Object
    },
    data() {
      return {
        plans: [
          'Explore',
          'Learn',
          'Grow'
        ],
        actualPlanSelect: false,
        futurePlanSelect: false,
        paidPlanSelect: false,
        actualPlan: this.item.actualPlan,
        futurePlan: this.item.futurePlan,
        paidPlan: this.item.paidPlan,
        billingPeriod: this.item.billingPeriod,
        freeUpgradeExpirationDate: this.item.freeUpgradeExpirationDate,
        nextPaymentDate: this.item.nextPaymentDate,
        lastPaymentDate: this.item.lastPaymentDate,
        trialExpired: this.item.trialExpired,
        buttonLoading: false,
      }
    },
    methods: {
      actualPlanAway() {
        this.actualPlanSelect = false;
      },
      futurePlanAway() {
        this.futurePlanSelect = false;
      },
      paidPlanAway() {
        this.paidPlanSelect = false;
      },
      updateSubscriptionHandler() {
        this.buttonLoading = true;
        const dataToSend = {
          actualPlan: this.actualPlan,
          freeUpgradeExpirationDate: this.freeUpgradeExpirationDate ? this.formatDate(this.freeUpgradeExpirationDate) : null,
          paidPlan: this.paidPlan,
          futurePlan: this.futurePlan,
          billingPeriod: this.billingPeriod,
          nextPaymentDate: this.nextPaymentDate ? this.formatDate(this.nextPaymentDate) : null,
          lastPaymentDate: this.lastPaymentDate ? this.formatDate(this.lastPaymentDate) : null,
          trialExpired: this.trialExpired
        }

        this.updateSubscription(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.buttonLoading = false;
          })
      },
      formatDate(date) {
        if (!(date instanceof Date)) {
          date = new Date(date);
        }
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
      }
    }
  }
</script>

<style scoped>
  .field :deep(.vue-daterange-picker) {
    width: 100%;
  }

  .field :deep(.vue-daterange-picker .form-control) {
    width: 100%;
    height: auto;
    margin: 0 !important;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 10px;
    outline: none;
    font-size: 16px;
    color: #171725;
  }

  .period-select {
    padding: 3px 12px;
    color: #696974;
    font-weight: 500;
    border: 1px solid #e2e2ea;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    margin-left: 2%;
  }

  .period-text {
    color: rgb(185, 35, 35);
  }

  .trial-expired-wrapper .checkbox{
    display: inline-block;
    margin-left: 1%;
    margin-bottom: 15px;
  }
</style>

<style>
  .datepicker input {
    padding-left: 6% !important;
  }
</style>
