<script>
  import axios from 'axios'
  

  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      getCourses() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/courses`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      getCourse(courseId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      createCourse(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      editCourse(courseId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}`, {
            method: 'PATCH',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      getModule(courseId, moduleId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      createModule(courseId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      editModule(courseId, moduleId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}`, {
            method: 'PATCH',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      getSection(courseId, moduleId, sectionId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}/section/${sectionId}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      createSection(courseId, moduleId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}/section`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      editSection(courseId, moduleId, sectionId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}/section/${sectionId}`, {
            method: 'PATCH',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      getSession(courseId, moduleId, sectionId, sessionId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}/section/${sectionId}/session/${sessionId}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      createSession(courseId, moduleId, sectionId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}/section/${sectionId}/session`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      updateSession(courseId, moduleId, sectionId, sessionId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}/section/${sectionId}/session/${sessionId}`, {
            method: 'PATCH',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      deleteModule(courseId, moduleId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}`, {
            method: 'DELETE'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.msg);
            })
        })
      },
      deleteSection(courseId, moduleId, sectionId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}/section/${sectionId}`, {
            method: 'DELETE'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.msg);
            })
        })
      },
      deleteSession(courseId, moduleId, sectionId, sessionId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/course/${courseId}/module/${moduleId}/section/${sectionId}/session/${sessionId}`, {
            method: 'DELETE'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.msg);
            })
        })
      },
      deleteSessionComment(commentId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/comments/remove/${commentId}`, {
            method: 'DELETE'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.msg);
            })
        })
      },
      getSessionComments(sessionId) {
        return new Promise((resolve, reject) => {
          axios(`${process.env.VUE_APP_API_URL}/knowledge/comments/session/${sessionId}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      reorderItems(type, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/knowledge/order/${type}`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      getTaskTypes() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/task-types`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },

      getSectionsByModule(moduleId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/knowledge/sections/by-module/${moduleId}`, {
            method: 'GET'
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },
    }
  }
</script>
